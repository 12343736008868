body {
  background-color: #282c34;
}

div#root {
  max-width: 100vw;
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  min-height: 100vh;
  text-align: center;
}

header {
  display: flex;
  background-color: #000;
  color: #fff;
  align-items: center;
}

header h1 {
  margin-left: 3%;
  font-style: italic;
  font-size: 1.5em;
  justify-self: flex-end;
}

.App-logo {
  width: 30vw;
  max-width: 375px;
  min-width: 100px;
  pointer-events: none;
  padding-left: 3%;
}

main {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex: 1 0 auto;
}

#spinContainer {
  position: absolute;
  padding-bottom: 20px;
}

#processCube {
  width: 200px;
  height: 200px;
}

.VictoryContainer {
  margin-top: -70px;
}

#results {
  width: 100%;
}

#results p {
  font-weight: bold;
  margin: 8px 0 15px 0;
  border-bottom: 1px solid;
  display: inline-block;
}

#camBlock {
  padding: 0 20px;
}

.snippet {
  display: flex;
  flex-direction: column;
  margin-right: 2%;
  flex: 1;
  justify-content: flex-end;
  color: #e79f23;
  text-align: right;
  font-weight: bold;
}

.snippet p {
  margin-right: 30px;
  font-size: 12pt;
}

.snippet a {
  display: flex;
  justify-content: flex-end;
}

#tflogo {
  width: 25vw;
  max-width: 140px;
}

.App-link {
  color: #e79f23;
}

#fpInfo {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  border-radius: 15px;
  max-width: 400px;
  padding: 20px;
  font-size: 1.2em;
  border: 2px solid rgba(255, 255, 255, 0.8);
}

#fpInfo h2 {
  border-bottom: 1px solid;
  color: #fff;
  padding-left: 5px;
}

#fpInfo h3 {
  color: #fff;
}

#fpInfo a {
  color: #e79f23;
}

#fpInfo li {
  padding: 0 0 20px 0;
}

.clickTarget {
  cursor: pointer;
}

#overDrop {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#underDrop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.7em;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0 0 15px 15px;
  padding: 0 15px;
}

.switchStation {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right;
}

.switchStation p {
  padding-right: 10px;
}

.photo-box {
  padding: 20px;
  border-width: 5px;
  border-color: #02bbd7;
  border-style: dashed;
}

.dropped-photo {
  height: 400px;
  max-width: 100%;
}

#predictions {
  min-height: 100px;
  margin-bottom: 20px;
}

#predictions ul {
  list-style-type: none;
  margin-top: -1%;
  margin-left: -2%;
}

#predictions ul li {
  font-size: 0.8em;
  color: #e79f23;
}

footer {
  align-items: center;
  background: #000;
  bottom: 0;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875em;
  font-weight: 600;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  width: 100vw;
}

.modelPicker {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 1.2em;
  align-items: center;
  padding-bottom: 100px;
}

.modelPicker p {
  font-size: 1.4em;
  padding-right: 7px;
}

footer div {
  display: inline-block;
  margin: 15px 30px;
}

footer a {
  color: #fff;
  text-decoration: none;
}
footer a:hover {
  color: #e79f23;
}

@media screen and (max-width: 600px) {
  header {
    flex-direction: column;
  }
  header h1 {
    font-size: 1.5em;
  }
  .snippet {
    visibility: hidden;
    display: none;
  }

  .VictoryContainer {
    margin-top: -40px;
  }

  #camDescription span {
    display: none;
  }

  #camDescription:after {
    content: 'Cam';
  }

  #blurDescription span {
    display: none;
  }

  #blurDescription:after {
    content: 'Protection';
  }

  #switchStation {
    padding-right: 5px;
  }

  #topMessage:after {
    content: ' - On mobile tap to add';
  }

  #predictions {
    padding-top: 10px;
  }
}
